import React from 'react';
import styles from './Footer.module.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram, faWhatsapp} from '@fortawesome/free-brands-svg-icons';

const Footer: React.FC = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                <div className={styles.contactSection}>
                    <a href="https://wa.link/kg2eib"
                       className={styles.instagramLink}>
                        <FontAwesomeIcon icon={faWhatsapp} size="10x"/>
                        <p className={styles.footerText}>
                            ¿Tienes alguna pregunta? ¡Contáctanos por WhatsApp!
                        </p>
                    </a>
                </div>
                <div className={styles.contactSection}>
                    <a href="https://instagram.com/studiocala_?igshid=MzRlODBiNWFlZA=="
                       className={styles.instagramLink}>
                        <FontAwesomeIcon icon={faInstagram} size="10x"/>
                        <p className={styles.footerText}>
                            ¡Síguenos en Instagram para estar al tanto de nuestras últimas actualizaciones!
                        </p>
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;