import React, {ChangeEvent, FC, useEffect, useState} from "react";
import axios from "axios";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import DatePicker from 'react-datepicker';
import {addDays, format, isBefore, isSunday, isThisMonth, isTuesday} from "date-fns";
import 'react-datepicker/dist/react-datepicker.css';
import styles from './RegisterAppointmentForm.module.css';
import IRegisterAppointmentRequest from "../../interfaces/IRegisterAppointmentRequest";

interface RegisterAppointmentFormProps {
}

const validationSchema = Yup.object().shape({
    name: Yup.string().trim().notRequired(),
    email: Yup.string().email('Correo electrónico inválido').required('El campo email es obligatorio').trim(),
    phoneNumber: Yup.string().matches(/^[0-9]+$/, 'El campo Número telefónico debe ser numérico')
        .min(9, 'El número telefónico debe tener exactamente 9 dígitos')
        .max(9, 'El número telefónico debe tener exactamente 9 dígitos')
        .notRequired()
        .trim(),
    service: Yup.string().required("Por favor, indique el servicio que desea.").trim(),
    time: Yup.string().required("El campo 'Hora de la consulta' es obligatorio.").trim(),
    cosmetologistId: Yup.number()
        .test('is-valid', 'Debe elegir una sucursal válida', (value) => {
            return value !== 0;
        })
        .required("Debe elegir una sucursal"),
});


const RegisterAppointmentForm: FC<RegisterAppointmentFormProps> = () => {
    const [availableHours, setAvailableHours] = useState<string[]>([]);
    const [selectedHour, setSelectedHour] = useState<string>('');
    const today = new Date().getDay() == 0 ? addDays(new Date(), 1) : new Date();
    const [selectedDate, setSelectedDate] = useState<any>(today);
    const [successfulRegistration, setSuccessfulRegistration] = useState<boolean>(false);
    const [isWaitingResponse, setIsWaitingResponse] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [services, setServices] = useState<any>();
    const [isUserRegistered, setIsUserRegistered] = useState<boolean>(false);

    const todayFormatted = format(today, 'yyyy-MM-dd');

    useEffect(() => {
        getFreeHours(todayFormatted, 1);
        getServices();
    }, []);

    const apiHost: string | undefined = process.env.REACT_APP_API_URL;

    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    const defaultTime: string = `${formattedDate} ${selectedHour}:00`;
    const [registerForm, setRegisterForm] = useState<IRegisterAppointmentRequest>({
        name: '',
        email: '',
        phoneNumber: '',
        service: 1,
        cosmetologistId: 0,
        time: defaultTime
    });

    const registerAppointment = async (values: IRegisterAppointmentRequest) => {
        setIsWaitingResponse(true);
        const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        const time: string = `${formattedDate} ${selectedHour}:00`;
        const request: IRegisterAppointmentRequest = {...values, time};
        const apiKey = process.env.REACT_APP_API_KEY;

        try {
            await axios.post(`${apiHost}/appointment`, request, {
                headers: {
                    'api-key': apiKey,
                }
            });
            setSuccessfulRegistration(true);
            setHasError(false);
            getFreeHours(formattedDate, values.cosmetologistId);
        } catch (e: any) {
            setSuccessfulRegistration(false);
            setHasError(true);
            setErrorMessage(e.response.data.message);
        } finally {
            setIsWaitingResponse(false);
        }
    };

    const getFreeHours = async (date: string, cosmetologistId: number) => {
        try {
            setIsWaitingResponse(true)
            const response = await axios.get(`${apiHost}/appointment/remaining-hours/location/${cosmetologistId}?date=${date}`);
            let {data: hours} = response;
            setAvailableHours(hours);
            setSelectedHour(hours[0]);
        } catch (e) {
            console.log(e);
        } finally {
            setIsWaitingResponse(false)
            setHasError(false)
        }
    };

    const getServices = async () => {
        try {
            const response = await axios.get(`${apiHost}/service`)
            const {data: services} = response;
            setServices(services)
        } catch (e) {
            console.log(`Error getting services ${e}`);
        }
    }

    const handleDateChange = async (date: Date, cosmetologistId: number) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        setSelectedDate(date);
        await getFreeHours(formattedDate, cosmetologistId);
    };

    const isSelectableDate = (date: Date, cosmetologistId: number) => {

        if (cosmetologistId == 1 && (isTuesday(date) || isSunday(date))) {
            return false;
        }

        if (cosmetologistId == 1 && [1, 2, 3, 4, 5, 6].includes(date.getDate()) && date.getMonth() === 10) {
            return false;
        }

        if (cosmetologistId == 3 && ![11, 12].includes(date.getDate()) && !isThisMonth(date)) {
            return false;
        }

        const today = new Date();
        return !isBefore(date, today);

    };
    const renderOptions = () => {
        const today = new Date();
        const currentHour = new Date().getHours();
        const currentMinutes = new Date().getMinutes();
        const isAfter17Hours = currentHour >= 17 && currentMinutes > 0;

        if (isWaitingResponse && !successfulRegistration) {
            return (
                <option key={0} value={""} selected={true}>Obteniendo horas disponibles, un momento por favor</option>)
        }

        if (availableHours.length == 0) {
            return (<option key={0} value={""} selected={true}>No quedan horas disponibles para la fecha</option>)
        }

        if (selectedDate.getTime() == today.getTime() && isAfter17Hours) {
            return (<option key={0} value={""} selected={true}>No quedan horas disponibles para la fecha</option>)
        }


        return availableHours.map((availableHour, index) => (
            <option key={index} value={availableHour.slice(0, 5)} selected={index === 0}>
                {availableHour.slice(0, 5)}
            </option>));

    };

    const renderLoader = () => (
        <div className={styles.loaderContainer}>
            <div className={styles.loader}></div>
        </div>
    );

    const renderSuccessMessage = () => (
        <p className={styles.successMessage}>
            Se registró su consulta con éxito. <br/>
            <span className={styles.emailVerification}>Por favor, verifique su correo electrónico.</span>
        </p>
    );

    const renderErrorMessage = () => (
        <p className={styles.errorMessage}>
            Hubo un error al registrar la consulta:<br/>
            <span>{errorMessage}</span>
        </p>
    );

    const locations: { [key: string]: number } = {'Centro': 1};

    const checkIfUserIsRegistered = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const email: string = e.target.value;
            const url: string = `${process.env.REACT_APP_USER_HOST}/user/${email}/is-registered`;
            const response = await axios.get<{ isRegistered: boolean }>(url);
            setIsUserRegistered(response.data.isRegistered);
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className={styles.container}>
            <Formik
                initialValues={registerForm}
                validationSchema={validationSchema}
                onSubmit={registerAppointment}
            >
                {({isSubmitting, values, setFieldValue}) => (
                    <Form>
                        <h2 className={styles.title}>Agenda</h2>
                        {isUserRegistered && (
                            <p className={styles.notice}>
                                ¡Ya estás registrado! Por lo que el nombre y el teléfono no son necesarios.
                            </p>
                        )}
                        <div className={styles.formGroup}>
                            <label htmlFor="email">Email:</label>
                            <Field type="email" id="email" name="email" onBlur={checkIfUserIsRegistered} required/>
                            <ErrorMessage name="email" component="div" className={styles.inputError}/>
                        </div>
                        {!isUserRegistered && <div className={styles.formGroup}>
                            <label htmlFor="name">Nombre:</label>
                            <Field type="text" id="name" name="name" {...(!isUserRegistered ? {required: true} : {})}/>
                            <ErrorMessage name="name" component="div" className={styles.inputError}/>
                        </div>}
                        {!isUserRegistered && <div className={styles.formGroup}>
                            <label htmlFor="phoneNumber">Número telefónico:</label>
                            <Field type="tel" id="phoneNumber"
                                   name="phoneNumber" {...(!isUserRegistered ? {required: true} : {})}/>
                            <ErrorMessage name="phoneNumber" component="div" className={styles.inputError}/>
                        </div>}
                        <div className={styles.formGroup}>
                            <label htmlFor="service">Servicio:</label>
                            <div className={styles.selectContainer}>
                                <Field as="select" name="service" value={values.service} required
                                       className={styles.select}>
                                    {services?.map((service: { id: any; name: any; price: any }) => (
                                        <option
                                            key={service.id}
                                            value={service.id}
                                            selected={service.id === values.service}
                                        >
                                            {service.name + ` $${service.price}`}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                            <ErrorMessage name="type" component="div" className={styles.inputError}/>
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="type">Sucursal:</label>
                            <div className={styles.selectContainer}>
                                <Field
                                    as="select"
                                    name="cosmetologistId"
                                    required
                                    className={styles.select}
                                    onChange={async (e: ChangeEvent<HTMLSelectElement>) => {
                                        const newValue = parseInt(e.target.value);
                                        await setFieldValue("cosmetologistId", newValue);
                                        await getFreeHours(format(selectedDate, 'yyyy-MM-dd'), newValue);
                                    }}
                                    value={values.cosmetologistId}
                                >
                                    <option key={0} value={0}>-</option>
                                    {Object.keys(locations).map((key) => {
                                        const value: number = locations[key];
                                        return <option key={key} value={value}>{key.trim()}</option>;
                                    })}
                                </Field>
                            </div>
                            <p className={styles.noticeBranch}>Nuestra otra localidad volverá a la brevedad, también en
                                Montevideo.</p>
                            <ErrorMessage name="cosmetologistId" component="div" className={styles.inputError}/>
                        </div>
                        <label htmlFor="date">Fecha:</label>
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date: Date) => handleDateChange(date, values.cosmetologistId)}
                            minDate={today}
                            filterDate={(date: Date) => isSelectableDate(date, values.cosmetologistId)}
                            dateFormat="dd/MM/yyyy"
                            className={styles.datePicker}
                        />
                        <div className={styles.formGroup}>
                            <label htmlFor="time">Hora:</label>
                            <Field as="select" id="time" name="time" value={selectedHour}
                                   onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedHour(e.target.value)}
                                   required>
                                {renderOptions()}
                            </Field>
                            <ErrorMessage name="time" component="div" className={styles.inputError}/>
                        </div>
                        {isWaitingResponse ? (
                            renderLoader()
                        ) : (
                            <>
                                {successfulRegistration && renderSuccessMessage()}
                                {hasError && renderErrorMessage()}
                                <button type="submit"
                                        disabled={isSubmitting || isWaitingResponse || (!isWaitingResponse && availableHours.length == 0)}
                                        className={styles.registerButton}>
                                    Registrar
                                </button>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default RegisterAppointmentForm;

