import React from 'react';
import styles from './Header.module.css';

interface HeaderProps {
    logoSrc: string;
    navLinks: { text: any; link: string }[];
}

const Header: React.FC<HeaderProps> = ({logoSrc, navLinks}) => {
    return (
        <header className={styles.header}>
            <nav className={styles.navbar}>
                <div className={styles.navLogo}>
                    <img src={logoSrc} alt="Logo" className={styles.logo}/>
                </div>
                <ul className={styles.navLinks}>
                    <li>
                        <span className={styles.companyName}>Studio Cala</span>
                    </li>
                    {navLinks.map((link, index) => (
                        <li key={index}>
                            <a href={link.link} className={styles.navLink}>
                                {link.text}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </header>
    );
};

export default Header;
