import React from 'react';
import './App.css';
import RegisterAppointmentForm from "./components/RegisterAppointmentForm/RegisterAppointmentForm";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

function App() {
    const headerLinks = [{text: "", link: ""}];
    return (
        <div>
            <Header logoSrc={'logo.png'} navLinks={headerLinks}/>
            <RegisterAppointmentForm/>
            <Footer/>
        </div>

    );
}

export default App;
